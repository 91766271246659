import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from 'react';
import {MatchList as SharedMatchList, MatchTile as SharedMatchTile} from "@/shared/Profile.jsx";
import {ProfileMatchlist} from "@/shared/Profile.style.jsx";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    div: "div",
    h2: "h2",
    img: "img",
    p: "p",
    pre: "pre",
    span: "span",
    ..._provideComponents(),
    ...props.components
  }, {MatchTileExpand} = _components;
  if (!MatchTileExpand) _missingMdxReference("MatchTileExpand", true);
  if (!React) _missingMdxReference("React", false);
  if (!React.Fragment) _missingMdxReference("React.Fragment", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "import MatchTileExpand from \"@/shared/MatchTileExpand.jsx\";\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Most (if not all) player profiles for a game have a running list of matches played, each of which is represented by a ", _jsx(_components.code, {
        children: "MatchTile"
      }), ". The ", _jsx(_components.code, {
        children: "MatchTile"
      }), " is a compact representation of a match, showing the match's date, result, and various stats. There are various click locations: the match tile itself, which should navigate to the match details page/route, and the expand button which should lazily render some additional info."]
    }), "\n", _jsx(_components.h2, {
      id: "example-match-tile-league-of-legends",
      children: _jsx(_components.a, {
        href: "#example-match-tile-league-of-legends",
        children: "Example Match Tile (League of Legends)"
      })
    }), "\n", _jsx(_components.code, {
      preview: true,
      className: "language-jsx",
      code: `<ProfileMatchlist>
  <SharedMatchList matchList={[5012856788]}>
    <React.Fragment key={5012856788}>
      <SharedMatchTile
        id={5012856788}
        height={142} // Roughly the height of the rendered match tile, for lazy loading
        match={{}}
      >
        <MatchTileExpand
          isWin={true}
          isMvp={true}
          image={{
            src: "https://blitz-cdn.blitz.gg/blitz/lol/champion/63.webp",
            alt: "Brand - League of Legends",
            style: {},
          }}
          tabDefault="MatchTileTabsEnum.Scoreboard"
          tabsToElement={{ "MatchTileTabsEnum.Scoreboard": () => import("@/game-lol/components/MatchScoreboard.jsx") }}
          tabsLabel={[
            {
              name: ["tft:matchtabs.scoreboard", "Scoreboard"],
              key: "MatchTileTabsEnum.Scoreboard",
            }
          ]}
          tabArgs={{
            matchId: 5012856788,
            region: "na1",
          }}
          urlWeb={"#"}
          urlCanonical={"#"}
          titles={[
            {
              text: "Victory",
              bold: true,
              color: "var(--turq)",
            },
            {
              text: "Ranked",
            }
          ]}
          matchDate={new Date()}
          aside={
            <div>
              <img src="https://blitz-cdn.blitz.gg/blitz/lol/champion/11.webp" width="60" height="60" />
              <span>Arbirary side component</span>
            </div>
          }
        >
          <div>Misc. custom component for rendering League specific info</div>
        </MatchTileExpand>
      </SharedMatchTile>
    </React.Fragment>
    <React.Fragment key={5012856789}></React.Fragment>
  </SharedMatchList>
</ProfileMatchlist>`,
      children: _jsx(ProfileMatchlist, {
        children: _jsxs(SharedMatchList, {
          matchList: [5012856788],
          children: [_jsx(React.Fragment, {
            children: _jsx(SharedMatchTile, {
              id: 5012856788,
              height: 142,
              match: {},
              children: _jsx(MatchTileExpand, {
                isWin: true,
                isMvp: true,
                image: {
                  src: "https://blitz-cdn.blitz.gg/blitz/lol/champion/63.webp",
                  alt: "Brand - League of Legends",
                  style: {}
                },
                tabDefault: "MatchTileTabsEnum.Scoreboard",
                tabsToElement: {
                  "MatchTileTabsEnum.Scoreboard": () => import("@/game-lol/components/MatchScoreboard.jsx")
                },
                tabsLabel: [{
                  name: ["tft:matchtabs.scoreboard", "Scoreboard"],
                  key: "MatchTileTabsEnum.Scoreboard"
                }],
                tabArgs: {
                  matchId: 5012856788,
                  region: "na1"
                },
                urlWeb: "#",
                urlCanonical: "#",
                titles: [{
                  text: "Victory",
                  bold: true,
                  color: "var(--turq)"
                }, {
                  text: "Ranked"
                }],
                matchDate: new Date(),
                aside: _jsxs(_components.div, {
                  children: [_jsx(_components.img, {
                    src: "https://blitz-cdn.blitz.gg/blitz/lol/champion/11.webp",
                    width: "60",
                    height: "60"
                  }), _jsx(_components.span, {
                    children: "Arbirary side component"
                  })]
                }),
                children: _jsx(_components.div, {
                  children: "Misc. custom component for rendering League specific info"
                })
              })
            })
          }, 5012856788), _jsx(React.Fragment, {}, 5012856789)]
        })
      })
    }), "\n", _jsx(_components.h2, {
      id: "visual-reference",
      children: _jsx(_components.a, {
        href: "#visual-reference",
        children: "Visual Reference"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Base parts of the core of a match tile:\n", _jsx(_components.img, {
        src: "https://blitz-cdn.blitz.gg/dev/docs-match-tile-1.jpg",
        alt: "docs match tile 1"
      })]
    }), "\n", _jsxs(_components.p, {
      children: ["Expanded match tile components. These are optional, without them there will not be an expand button\n", _jsx(_components.img, {
        src: "https://blitz-cdn.blitz.gg/dev/docs-match-tile-2.jpg",
        alt: "docs match tile 2"
      })]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
